<template>
    <div class="modal-edit-note overlay" v-if="showModal" @click="closeModal">
        <div class="modal-box" @click.stop>
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <textarea  :class="['form-control',{invalid: (!$v.editedNote.note.required && submitNote)}]"  v-model="editedNote.note"  placeholder="Додайте нотаток"></textarea>
                  <small class="error" v-if="!$v.editedNote.note.required && submitNote">Поле нотаток не повинно бути порожнім</small>
                  <div class="button-container">
                    <button @click="save">Зберегти</button>
                    <button @click="closeModal">Скасувати</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import {email, minLength, required} from "vuelidate/lib/validators";
export default {
    props: {
        showModal: Boolean,
        noteToEdit: Object // Заметка, которую нужно отредактировать/удалить
    },
    data() {
        return {
          editedNote: {note:""},
          submitNote: false,
        };
    },
    validations: {
      editedNote: {
        note: {required}
      },
    },
    watch:{
      "showModal":function (newval,oldval){
        if(newval){
          if(this.noteToEdit){
            this.editedNote = { ...this.noteToEdit };
          }else{
            this.editedNote.note = "";
          }
        }
      },
    },
    methods: {
        save() {
            this.submitNote = true;

            if(this.$v.$invalid){
              this.$v.$touch()
              return
            }

            this.$emit('save', this.editedNote);
        },
        closeModal() {
            this.$emit('close');
        },
        confirmDelete() {
            if (confirm('Вы уверены, что хотите удалить эту заметку?')) {
                this.$emit('delete', this.noteToEdit);
            }
        }
        // Другие методы
    }
};
</script>

<style>
.modal-edit-note.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}
.modal-edit-note .form-group{
  margin: 0px;
}
.modal-edit-note .modal-box {
    background-color: white;
    padding: 20px;
    border-radius: 3px;
    width: 400px;
}

.modal-edit-note .modal-box textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    resize: none;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.modal-edit-note .button-container {
    text-align: right;
}

.modal-edit-note .modal-box button {
  border-radius: 3px;
  color: var(--button-color);
  background-color: var(--button-background-color);
  border: 0;
  font-size: 1rem;
  cursor: pointer;
  font-weight: var(--font-weight-bold);
  outline: none;
  min-height: 50px;
  width: 50%;
}
.modal-edit-note .modal-box button:hover {
  background-color: var(--button-background-color-hover);
}
.modal-edit-note .modal-box button:last-child {
    margin-right: 0;
}

.modal-edit-note .modal-box button:focus {
    outline: none;
}
</style>
