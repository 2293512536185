export function orderTourism(offerInfo, searchInfo, userInfo, touristsListInfo, orderId, params, bonusSystemValue = 0) {
    return axios({
        url: `/api/test/insurance/tourism/order/create`,
        method: 'post',
        data: {
            params:params,
            orderId: orderId,
            offerId: offerInfo.offerId,
            orderDraft: offerInfo?.orderDraft,
            price: offerInfo.price,
            bonusSystemValue: bonusSystemValue,
            fullPrice: offerInfo.fullPrice,
            countryId: searchInfo.country.id,
            startDate: searchInfo.date,
            endDate: searchInfo.end,
            periodOption: searchInfo.periodOption,
            yearProgram: searchInfo.yearProgram,
            priceTrip: searchInfo.priceTrip,
            allowedDocuments: offerInfo.allowed_documents,
            info: {
                identificationCode: userInfo.identificationCode,
                withoutIdentificationCode: userInfo.withoutIdentificationCode,
                name: userInfo.name,
                surname: userInfo.surname,
                dateBirth: userInfo.dateBirth,
                phone: userInfo.phone,
                mail: userInfo.mail,
                city: userInfo.city,
                street: userInfo.street,
                house: userInfo.house,
                apartment: userInfo.apartment,

                //
                documentation: userInfo.documentation,
                documentType: userInfo.documentType,
                passportSerial: userInfo.passportSerial,
                passportNumber: userInfo.passportNumber,
                passportDate: userInfo.passportDate,
            },
            touristsListInfo:touristsListInfo,
            offerInfo:offerInfo,
            searchInfo:searchInfo
        },
    });
}
