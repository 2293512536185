<template>
    <div class="modal fade bd-example-modal-lg"  id="companyBranches" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                        <h5 class="modal-title">Філії</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row text-center">
                        <div class="form-group offset-lg-4 col-lg-4">
                            <select @change="reloadBranches()" v-model="selectCityId" class="form-control" id="exampleFormControlSelect1">
                                <option :value="city.id" v-for="city in uniqueCities" >{{ city.name_full_name_ua }}</option>
                            </select>
                        </div>
                    </div>
                   <div :class="(index === 0) ? 'main' : '' " class="row row-data" v-for="(branch, index) in branches" :key="branches.id">
                       <div class="col-md-4">
                            <div style="white-space: pre-wrap;" class="type">
                                {{ branch.type }}
                            </div>
                            <div style="white-space: pre-wrap;" v-html="branch.address + '<br>' +
                                branch.cityData.name_full_name_ua " class="main-office-address"></div>
                       </div>
                       <div class="col-md-4">
                           <div class="type">
                               Графік роботи
                           </div>
                           <div v-if="branch.workTime" style="white-space: pre-wrap;" v-html="branch.workTime" class="schedule"></div>
                           <div v-else class="schedule">
                               &mdash;
                           </div>
                       </div>
                       <div class="col-md-4">
                           <div class="type">
                               Контакти
                           </div>
                           <div v-if="branch.communications" style="white-space: pre-wrap;" v-html="branch.communications" class="schedule"></div>
                           <div v-else class="schedule">
                               &mdash;
                           </div>
                       </div>
                   </div>
               </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            branches: {},
            currentCity: {},
            uniqueCities: [],
            selectCityId: null,
            dialogVisible: false
        };
    },
    methods: {
        close() {
            this.branches = {};
            $('#companyBranches').modal('hide');
        },
        open: function (branches, currentCity) {
            let uniqueCities = [];
            uniqueCities.push({name_full_name_ua: currentCity.cityName, id: currentCity.id});

            _.each(branches, (index, key) => {
                if (uniqueCities.find(city => Number(city.id) === index.cityData.id) === undefined) {
                    uniqueCities.push(index.cityData);
                }
            });

            this.uniqueCities = uniqueCities;

            this.selectCityId = currentCity.id
            this.mainBransh = branches[0];
            branches.shift();
            this.allBranches = branches;
            this.reloadBranches();
            this.currentCity = currentCity

            $('#companyBranches').modal('show');
        },
        reloadBranches: function() {
            this.branches = this.getBranches();
        },
        getBranches: function() {
            let branches = [];
            branches.push(this.mainBransh);
            let branchesByCity = this.allBranches.find(branch => Number(branch.cityData.id) === Number(this.selectCityId))

            if (branchesByCity !== undefined) {
                branches = branches.concat(branchesByCity);
            }

            return branches;
        }
    }
};
</script>

<style lang="scss" scoped>

    @media (min-width: 992px) {
        .modal-lg, .modal-xl {
            max-width: 1170px;
        }
    }

    .row-data:not(:last-child) {
        border-bottom: 1px solid #DDE2E5;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }

    .row-data:last-child {
        margin-bottom: 20px;
    }

    .row-data.main {
        .main-office-address, .schedule {
            font-weight: 700
        }
    }

    .type {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */


        color: #999DA6;
    }

    .main-office-address {
        background: #FFFFFF;
        border-radius: 5px;
    }

    .schedule {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */


        color: #24282C;
    }

</style>
