import axios from "axios";

/**
 *
 * @param data
 * @returns {*}
 */
export function saveClientNotes(data) {
    return axios({
        url: `/api/${process.env.VUE_APP_SERVER_MODE}/client-note`,
        method: 'POST',
        data: data,
    });
}
/**
 *
 * @param data
 * @returns {*}
 */
export function deleteClientNotes($client_note_id) {
    return axios({
        url: `/api/${process.env.VUE_APP_SERVER_MODE}/client-note/`+$client_note_id,
        method: 'DELETE',
    });
}

export function updateClientNote(data) {
    return axios({
        url: `/api/${process.env.VUE_APP_SERVER_MODE}/client-note/`+data['clients_id'],
        method: 'PUT',
        data: data,
    });
}

export function editClientNote(data) {
    return axios({
        url: `/api/${process.env.VUE_APP_SERVER_MODE}/client-note/`+data['clients_id']+`/edit`,
        method: 'GET',
        data: data,
    });
}

/**
 *
 * @param params
 * @returns {*}
 */
export function getData(params) {
    return axios({ url: `/api/${process.env.VUE_APP_SERVER_MODE}/client-note`,
        method: 'GET',
        params: params
    });
}
