<template>
    <div class="row order-data">
        <div class="col-6">
            <div class="info-block">
                <div>
                    <PuSkeleton width="100px" class="skeleton-input"></PuSkeleton>
                </div>
                <div>
                    <PuSkeleton width="100px" class="skeleton-input"></PuSkeleton>
                </div>
<!--                <div class="option">Тип поліса</div>-->
<!--                <div class="value">ОСАГО</div>-->
            </div>
            <div class="info-block">
                <div class="option">
                    <PuSkeleton width="100px" class="skeleton-input"></PuSkeleton>
                </div>
                <div class="sub-options">
                    <div>
                        <PuSkeleton width="100px" class="skeleton-input"></PuSkeleton>
                    </div>
                    <div>
                        <PuSkeleton width="100px" class="skeleton-input"></PuSkeleton>
                    </div>
                    <div>
                        <PuSkeleton width="100px" class="skeleton-input"></PuSkeleton>
                    </div>
                </div>
            </div>
            <div class="info-block">
                <div class="option">
                    <PuSkeleton width="100px" class="skeleton-input"></PuSkeleton>
                </div>
                <div class="sub-options">
                    <div>
                        <PuSkeleton width="100px" class="skeleton-input"></PuSkeleton>
                    </div>
                    <div>
                        <PuSkeleton width="100px" class="skeleton-input"></PuSkeleton>
                    </div>
                    <div>
                        <PuSkeleton width="100px" class="skeleton-input"></PuSkeleton>
                    </div>

                    <div class="mt-5">
                        <PuSkeleton width="100px" class="skeleton-input"></PuSkeleton>
                    </div>
                    <div>
                        <PuSkeleton width="100px" class="skeleton-input"></PuSkeleton>
                    </div>
                    <div>
                        <PuSkeleton width="100px" class="skeleton-input"></PuSkeleton>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="info-block">
                <div class="option">
                    <PuSkeleton width="100px" class="skeleton-input"></PuSkeleton>
                </div>
                <div class="value">
                    <PuSkeleton width="100px" class="skeleton-input"></PuSkeleton>
                </div>
            </div>
            <div class="info-block">
                <div class="option">
                    <PuSkeleton width="100px" class="skeleton-input"></PuSkeleton>
                </div>
                <div class="value">
                    <PuSkeleton width="100px" class="skeleton-input"></PuSkeleton>
                </div>
                <div class="value">
                    <PuSkeleton width="100px" class="skeleton-input"></PuSkeleton>
                </div>
                <div class="value">
                    <PuSkeleton width="100px" class="skeleton-input"></PuSkeleton>
                </div>
            </div>
            <div class="info-block">
                <div class="option">
                    <PuSkeleton width="100px" class="skeleton-input"></PuSkeleton>
                </div>
                <div class="sub-options">
                    <div>
                        <PuSkeleton width="100px" class="skeleton-input"></PuSkeleton>
                    </div>
                    <div>
                        <PuSkeleton width="100px" class="skeleton-input"></PuSkeleton>
                    </div>
                    <div>
                        <PuSkeleton width="100px" class="skeleton-input"></PuSkeleton>
                    </div>

                    <div class="mt-5">
                        <PuSkeleton width="100px" class="skeleton-input"></PuSkeleton>
                    </div>
                    <div style="position: absolute;bottom: 0;">
                        <PuSkeleton width="100px" class="skeleton-input"></PuSkeleton>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'RowMobileBlockSkeleton',
}
</script>
