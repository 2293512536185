<template>
    <div v-click-outside="hide"  class="bottom-block">
        <div  v-if="condition.isShow" style="position: relative">
            <div class="more-options">
                <p class="option">
                    <span class="icon">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_4_312)"><path d="M0.666748 8C0.666748 8 3.33341 2.66666 8.00008 2.66666C12.6667 2.66666 15.3334 8 15.3334 8C15.3334 8 12.6667 13.3333 8.00008 13.3333C3.33341 13.3333 0.666748 8 0.666748 8Z" stroke="#24282C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z" stroke="#24282C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_4_312"><rect width="16" height="16" fill="white"/></clipPath></defs>
                        </svg>
                    </span>
                    <a @click="fullClientInfo()" href="javascript:void(0);">
                        <span class="text">Детальна інформація</span>
                    </a>
                </p>
<!--                <p class="option">
                    <span class="icon">
                     <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_4_308)"><path d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z" stroke="#24282C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M3.33325 10H2.66659C2.31296 10 1.97382 9.85953 1.72378 9.60948C1.47373 9.35943 1.33325 9.02029 1.33325 8.66667V2.66667C1.33325 2.31305 1.47373 1.97391 1.72378 1.72386C1.97382 1.47381 2.31296 1.33334 2.66659 1.33334H8.66659C9.02021 1.33334 9.35935 1.47381 9.60939 1.72386C9.85944 1.97391 9.99992 2.31305 9.99992 2.66667V3.33334" stroke="#24282C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_4_308"><rect width="16" height="16" fill="white"/></clipPath></defs>
                      </svg>
                    </span>
                    <a @click="copyClient()" href="javascript:void(0);">
                        <span class="text">Копіювати клієнта</span>
                    </a>
                </p>-->
                <p class="option">
                    <span class="icon">
                        <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 5.66667V15.1111H3V5.66667H11ZM9.5 0H4.5L3.5 0.944444H0V2.83333H14V0.944444H10.5L9.5 0ZM13 3.77778H1V15.1111C1 16.15 1.9 17 3 17H11C12.1 17 13 16.15 13 15.1111V3.77778Z" fill="black"/>
                        </svg>
                    </span>
                    <a @click="deleteClient()" href="javascript:void(0);">
                        <span class="text">Видалити клієнта</span>
                    </a>
                </p>
            </div>
        </div>
        <a @click="handle()" href="javascript:void(0);" class="option image">
            <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="2" cy="2" r="2" fill="#999DA6"/>
                <circle cx="9" cy="2" r="2" fill="#999DA6"/>
                <circle cx="16" cy="2" r="2" fill="#999DA6"/>
            </svg>
        </a>
    </div>
</template>


<script>
import ClickOutside from 'vue-click-outside'
import { orderMixin } from '@/mixin'

export default {
    name: 'MoreOptions',
    props: ['statusOrder', 'isExistMtsbuLink', 'orderType'],
    mixins: [orderMixin],
    directives: {ClickOutside},
    data: function () {
        return {
            condition: {
                isShow: false,
            },
        }
    },
    computed: {
        listOptions: function() {
            let list = {};

            list[this.concludeStatus] = [
                'download_draft',
                'download_contract',
                'show_order',
                'copy_order',
                'all_payment_ways',
                // 'payment_invoice',
                // 'instant_loan',
                'show_mtsbu'
            ];
            list[this.draftStatus] = [
                'download_draft',
                'continue_order',
                'show_order',
                'copy_order'
            ];
            list[this.declaredStatus] = [
                'download_draft',
                'confirm_order',
                'show_order',
                'copy_order',
                'all_payment_ways',
                // 'payment_invoice',
                // 'instant_loan',
                'show_mtsbu',
            ];

            list[this.overdueStatus] = [
                'download_draft',
                'show_order',
                'copy_order',
            ];

            list[this.canceledStatus] = [
                'show_order',
                'copy_order',
            ];

            return list;
        },
    },
    events: {},
    methods: {
        fullClientInfo: function () {
          this.hide();
          return this.$emit('full-client-info');
        },
        copyClient: function () {
          this.hide();
          return this.$emit('copy-client');
        },
        deleteClient: function () {
          this.hide();
          return this.$emit('delete-client');
        },

        hide: function () {
            return this.condition.isShow = false;
        },
        handle: function () {
            return this.condition.isShow = ! this.condition.isShow
        }
    }
}
</script>

<style lang="scss" scoped>
.more-options {
    top: 20px;
    z-index: 2;
    width: 215px;
    border-radius: 5px;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);

    .option {
        margin-top: 10px;
        margin-left: 21px;

        a {
            color: #24282C;
            font-size: 14px;
            line-height: 21px;
            font-family: Circe;
            font-style: normal;
            font-weight: normal;

            .icon {

            }

            .text {
                height: 21px;
                margin-left: 5px;
            }
        }
    }
}
</style>
