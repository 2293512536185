<template>
    <div v-if="dialogVisible" class="modal-mask otp__modal otp__modal">
        <div class="custom-modal-wrapper">
            <div class="custom-modal-container">
                <div class="otp">
                    <div class="content">
                      <div class="otp__heading" style="font-size: 20px;">Оберіть варіант відправки коду</div>
                      <div class="col-lg-12">
                        <div class="radiolist d-flex justify-content-center">
                          <div class="radioitem initial-magrin">
                            <input type="radio" name="type" :id="1" :checked="type === 1">
                            <label for="1" @click="type = 1">Email</label>
                            <div class="check"></div>
                          </div>
                          <div class="radioitem initial-magrin">
                            <input type="radio" name="type" :id="2" :checked="type === 2">
                            <label for="2" @click="type = 2">Viber/Sms</label>
                            <div class="check"></div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12 text-center position-relative">
                        <button
                            type="button"
                            v-on:click="sendOtpApi()"
                            class="btn btn-blue">
                          Відправити
                        </button>
                      </div>
                      <div class="col-lg-12 text-center position-relative">
                        <button  @click="close()" class="btn-outline-blue btn" data-dismiss="modal">
                          Скасувати
                        </button>
                      </div>
                    </div>
                    <div v-if="this.preloader" class="modal-preloader">
                        <div class="spinner"></div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChoiceOtpSendingDirection',
    data() {
        return {
            dialogVisible: false,
            preloader: false,
            type:1,
        };
    },
    created() {

    },
    methods: {
        close() {
            this.dialogVisible = false;
        },
        open: function (orderId, price, usageMonths) {
            this.dialogVisible = true;
          },
      sendOtpApi:function (){
        this.dialogVisible = false;
        this.$emit('sendOtpApi', this.type);
      }
    }
};
</script>


<style lang="scss" scoped>
.modal-mask {
    position: fixed;
    z-index: 1999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}
.otp__modal{
  .btn{
    width: 100%;
    margin-bottom: 22px;
  }
  .radiolist{
    margin: 35px 0;
  }
}
.custom-modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.custom-modal-container {
    @media (max-width: 992px) {
        width:358px;
    }
    @media (max-width: 358px) {
      width: calc(100% - 60px);
      .otp__heading{
        text-align: center;
      }
    }

    width: 350px;
    height: 297px;
    background: #FFFFFF;
    border-radius: 5px;
    margin: 0 auto;
    padding: 20px 30px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

@media (max-width: 290px) {
  .custom-modal-container{
    padding-left: 0;
    padding-right: 0;
    display: block!important;
    height: auto;
  }
  .otp__modal{
    .radiolist{
      display: grid!important;
    }
  }
}


.custom-modal-body {
    margin: 20px 0;
}

.modal-default-button {
    float: right;
}

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .custom-modal-container,
.modal-leave-active .custom-modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

</style>
